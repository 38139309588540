import {Form, Input, InputNumber, Select, Space, Switch} from 'antd';
import React, {useEffect} from 'react';
import {CARD_TYPES, LOCALE} from '../../common';
import {useDispatch, useSelector} from 'react-redux';
import {RegistySelectors} from '../../store/RegistryStore';
import {RegistyAPI} from '../../store/RegistryStore/methods';

const {Item} = Form;
const {COMMON, PLACEHOLDERS, EDUCATION} = LOCALE;

const ProgramTypesForm = ({fullName = ''}) => {
  const dispatch = useDispatch();
  const conclusionCertificates = useSelector(RegistySelectors.conclusionCertificates);

  useEffect(() => {
    dispatch(RegistyAPI.conclusion.get());
  }, []);

  const parser = new DOMParser();
  const certificates = conclusionCertificates.map((certificate) => {
    let text = parser.parseFromString(certificate.text, 'text/html').documentElement.textContent;

    if (text.includes(`"{{trainingProgramText}}"`)) {
      text = text.replace(`"{{trainingProgramText}}"`, fullName);
    }
    if (text.includes(`{{dateString}}`)) {
      text = text.replace(`{{dateString}}`, 'даты');
    }
    if (text.includes(`{{hoursCount}}`)) {
      text = text.replace(`{{hoursCount}}`, 'нескольких часов');
    }

    return {...certificate, text};
  });

  return (
    <>
      <Item name="name" label={COMMON.TITLE} rules={[{required: true}]}>
        <Input autoFocus placeholder={PLACEHOLDERS.TITLE} />
      </Item>
      <Item name="fullName" label={COMMON.FULL_TITLE} rules={[{required: true}]}>
        <Input placeholder={PLACEHOLDERS.FULL_TITLE} />
      </Item>
      <Item name="normativeDocument" label={EDUCATION.NORMATIVE_DOCUMENT}>
        <Input.TextArea style={{height: '180px', resize: 'none'}} rows={4} placeholder={EDUCATION.NORMATIVE_DOCUMENT} />
      </Item>
      <Item name="inspector" label={EDUCATION.INSPECTOR}>
        <Input placeholder={PLACEHOLDERS.FULL_NAME} />
      </Item>
      <Item label={EDUCATION.CARD_VALIDITY} required>
        <Space style={{width: '100%'}}>
          <Item name="expire" noStyle rules={[{required: true}]}>
            <InputNumber min="0" step="1" style={{width: '100%'}} />
          </Item>
          <div>{EDUCATION.ENDLESS}</div>
        </Space>
        <div style={{fontSize: '8pt'}}>{EDUCATION.ONLY_FOR_ALGORITHM}</div>
      </Item>
      <Item name="cardTypeId" label={EDUCATION.CARD_TYPE} rules={[{required: true}]}>
        <Select placeholder="Повышение квалификации" listHeight={'max-content'}>
          {CARD_TYPES.map((type) => (
            <Select.Option key={type.value} value={type.value}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Item>
      <Item name="oldPrice" label={'Старая цена'} labelCol={{span: 20}}>
        <InputNumber min={0} placeholder={0} step={'1'} stringMode />
      </Item>
      <Item name="isEnableKP" label={EDUCATION.SHOW_IN_KP} labelCol={{span: 20}} valuePropName="checked">
        <Switch />
      </Item>
      <Item name="isHide" label={EDUCATION.SHOW_IN_LIST} labelCol={{span: 20}} valuePropName="checked">
        <Switch />
      </Item>
      <Item name="isEisot" label={EDUCATION.IS_EISOT} labelCol={{span: 20}} valuePropName="checked">
        <Switch />
      </Item>
      <Item name="needSNILS" label={EDUCATION.NEED_SNILS} labelCol={{span: 20}} valuePropName="checked">
        <Switch />
      </Item>
      <Item name="needDatName" label={EDUCATION.NEED_DAT_NAME} labelCol={{span: 20}} valuePropName="checked">
        <Switch />
      </Item>
      <Item name="needINN" label={EDUCATION.NEED_INN} labelCol={{span: 20}} valuePropName="checked">
        <Switch />
      </Item>
      <Item
        name="needDiplomForAll"
        label={EDUCATION.NEED_DIPLOMA_FOR_ALL}
        labelCol={{span: 20}}
        valuePropName="checked">
        <Switch />
      </Item>
      <Item
        name="needDiplomForTests"
        label={EDUCATION.NEED_DIPLOMA_FOR_TESTS}
        labelCol={{span: 20}}
        valuePropName="checked">
        <Switch />
      </Item>
      <Item name="canMultiSelect" label={EDUCATION.CAN_MULTI_SELECT} labelCol={{span: 20}} valuePropName="checked">
        <Switch />
      </Item>
      <Item name="conclusionCertificateId" label={EDUCATION.CONCLUSION_CERTIFICATE_ID}>
        <Select allowClear placeholder="Аттестован в соответствии с занимаемой должностью">
          {certificates.map((certificate) => (
            <Select.Option key={certificate.id} value={certificate.id}>
              {certificate.text}
            </Select.Option>
          ))}
        </Select>
      </Item>
      <Item name="conclusionProtocolId" label={EDUCATION.CONCLUSION_PROTOCOL_ID}>
        <Select allowClear placeholder="Может быть допущен(а) к работе без применения средств подмащивания, ">
          {certificates.map((certificate) => (
            <Select.Option key={certificate.id} value={certificate.id}>
              {certificate.text}
            </Select.Option>
          ))}
        </Select>
      </Item>
    </>
  );
};
export default ProgramTypesForm;
